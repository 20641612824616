import React, { Component } from 'react';
import axios from 'axios';
import RedirectAppointmentDetail from './RedirectAppointmentDetail';
import NoAppointments from './NoAppointments';
import Searching from './Searching';
import { getApiURL } from '../Config';
import Heading from './common/Heading';

class RedirectApptList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      appointmentsFound: false,
      appointmentsStage: 'searching',
      basicUser: {
        userId: 0,
        userName: 'unknown'
      }
    };
    this.basicUser = { userId: 0, userName: 'unknown' };
    this.resetAppointmentsState = this.resetAppointmentsState.bind(this);
  }
  resetAppointmentsState = () => {
    debugger;
    this.setState({ appointmentsFound: false, appointmentsStage: 'searching' });
  };

  getAppointments = async (userId, userName) => {
    // console.log(
    //   'User Filter Decoded: ' + atob(sessionStorage.getItem('userFilter'))
    // );
    // console.log('User Filter Encoded: ' + sessionStorage.getItem('userInfoA'));
    var apiURL = getApiURL();
    const appointmentURI =
      apiURL + '/redirchatappointment/' + userId + '/' + userName;
    //  +
    //   this.state.userId +
    //   '/' +
    //   this.state.userName;
    //
    //ZWNjdGVzdDMsRmVycmV0NFU=

    console.log(appointmentURI);

    axios
      .get(appointmentURI, { timeout: 15000 })
      .then(res => {
        console.log(res);
        this.setState({ appointments: res.data });
        //debugger;
        if ((res.data[0].expert_full_name === 'No Appointments') === true) {
          this.setState({
            appointmentsFound: false,
            appointmentsStage: 'none'
          });
        }
        if (res.data[0].appointment_id > -1) {
          this.setState({
            appointmentsFound: true,
            appointmentsStage: 'found'
          });
        } else {
          this.setState({
            appointmentsFound: false,
            appointmentsStage: 'none'
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });

    // console.log(res);
    // this.setState({ appointments: res.data });
    // debugger;
    // if (res.data[0].appointment_id > 0) {
    //   this.setState({ appointmentsFound: true });
    // }

    //debugger;
  };

  componentWillMount() {
    const userId = 8;
    const userName = 'Unknown';
    this.basicUser.userId = userId;
    this.basicUser.userName = userName;
    this.setState({ basicUser: { userId: userId, userName: userId.Name } });
    this.resetAppointmentsState();
  }
  componentDidMount() {
    const { userId, userName } = this.props.match.params;
    this.setState({ basicUser: { userId: userId, userName: userId.Name } });
    sessionStorage.setItem('redirUserName', userName);
    sessionStorage.setItem('userId', userId);
    this.getAppointments(userId, userName);
  }
  appointmentsFound = false;

  renderAppointments() {
    return this.state.appointments.map(appointment => (
      <RedirectAppointmentDetail
        key={appointment.appointment_id}
        appointment={appointment}
        basicUser={this.basicUser}
        //hideHeadingDuringChat={hideHeadingDuringChat.bind(this)}
      />
    ));
  }

  render() {
    //var hideHeadingDuringChat = this.props.hideHeadingDuringChat;
    return (
      <div className="row justify-content-center">
        <Heading />
        <div className="w-100" />
        <div className="col-md-10 col-lg-10 col-xl-10">
          <div>Appointment List</div>
          <hr />
          {/* <button onClick={() => hideHeadingDuringChat(true)}>
            Test Function
          </button> */}
          {this.state.appointmentsStage === 'searching' && <Searching />}
          {this.state.appointmentsFound === true && this.renderAppointments()}
          {this.state.appointmentsStage === 'none' && <NoAppointments />}
          {/* {this.state.appointmentsStage === 'searching' && <Searching />}
          {this.state.appointmentsStage === 'found' &&
            this.renderAppointments()}
          {!this.state.appointmentsStage === 'none' && <NoAppointments />} */}
        </div>
      </div>
    );
  }
}

export default RedirectApptList;
