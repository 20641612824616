import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Testing extends Component {
  render() {
    return (
      <div>
        <div>Click To Test Redirect Page</div>
        <div>
          <Link
            className="btn btn-success btn-sm btn-link"
            to={{
              pathname:
                '/kaigieredirector/2_MX40NDcyNTkwMn5-MTU1MzAxOTM5NjE4Mn5FVjIyYXR0UFVnRkFnRXhYU2FibFMwalp-fg/612/1/ecctest3/N/8'
            }}
          >
            /Test Redirect Page
          </Link>
        </div>
      </div>
    );
  }
}
