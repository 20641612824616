const baseURL = 'https://www.kaigie.com/rvc/';

const apiURL = 'https://mapi.kaigie.com/api';
const kaigieBase = 'https://www.kaigie.com';

const expertParams =
  '/2_MX40NDcyNTkwMn5-MTU1MzAxOTM5NjE4Mn5FVjIyYXR0UFVnRkFnRXhYU2FibFMwalp-fg/612/8/bencasey1/Y/8';
const userParams =
  '/2_MX40NDcyNTkwMn5-MTU1MzAxOTM5NjE4Mn5FVjIyYXR0UFVnRkFnRXhYU2FibFMwalp-fg/612/1/ecctest3/N/8';
// switching local host to mapi/www.kaigie.com
const getApiURL = () => {
  const hostName = window.location.hostname;
  switch (hostName) {
    case 'kaigie.com':
      return 'https://mapi.kaigie.com/api';
    case 'in.kaigie.com':
      return 'https://inapi.kaigie.com/api';
    case 'prod.kaigie.com':
      return 'https://mobapi.kaigie.com/api';
    case 'www.kaigie.com':
      return 'https://mapi.kaigie.com/api';
    case 'vc.kaigie.com':
      return 'https://mapi.kaigie.com/api';
    default:
      // testing against mobapi for eccdnn.com sites
      return 'https://mobapi.kaigie.com/api';
  }
};

const getTextURL = () => {
  const hostName = window.location.hostname;
  switch (hostName) {
    case 'kaigie.com':
      return 'https://tc.kaigie.com/index.html';
    case 'prod.kaigie.com':
      return 'https://tc2.kaigie.com/index.html';
    case 'www.kaigie.com':
      return 'https://tc.kaigie.com/index.html';
    case 'vc.kaigie.com':
      return 'https://tc.kaigie.com/index.html';
    case 's3vc.eccdnn.com':
      return 'https://tc2.kaigie.com/index.html';
    case 'vc.eccdnn.com':
      return 'https://tc2.kaigie.com/index.html';
    default:
      // testing against mobapi for redirect
      return 'https://tc2.kaigie.com/index.html';
  }
};
const getBaseURL = () => {
  const hostName = window.location.hostname;
  switch (hostName) {
    case 'kaigie.com':
      return 'https://kaigie.com/rvc';
    case 'prod.kaigie.com':
      return 'https://prod.kaigie.com/rvc';
    case 'www.kaigie.com':
      return 'https://www.kaigie.com/rvc';
    default:
      return 'https://www.kaigie.com/rvc';
  }
};

export {
  baseURL,
  apiURL,
  kaigieBase,
  getApiURL,
  getBaseURL,
  expertParams,
  userParams,
  getTextURL
};
