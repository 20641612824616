import React from 'react';

export default function NoAppointments() {
  debugger;
  return (
    <div>
      <h4>No Appointments Found!</h4>
    </div>
  );
}
