import React, { Component } from 'react';
import axios from 'axios';
import AppointmentDetail2 from './AppointmentDetail2';
import NoAppointments from './NoAppointments';
import Searching from './Searching';
import { getApiURL } from '../Config';
import Heading from './common/Heading';

class AppointmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      appointmentsFound: false,
      appointmentsStage: 'searching'
    };
    this.resetAppointmentsState = this.resetAppointmentsState.bind(this);
  }
  resetAppointmentsState = () => {
    debugger;
    this.setState({ appointmentsFound: false, appointmentsStage: 'searching' });
  };
  componentWillMount() {
    this.resetAppointmentsState();
  }
  componentDidMount() {
    this.getAppointments();
  }

  appointmentsFound = false;

  getAppointments = async () => {
    console.log(
      'User Filter Decoded: ' + atob(sessionStorage.getItem('userFilter'))
    );
    console.log('User Filter Encoded: ' + sessionStorage.getItem('userInfoA'));
    var apiURL = getApiURL();
    const appointmentURI =
      apiURL + '/chatappointment/' + sessionStorage.getItem('userInfoA');
    //ZWNjdGVzdDMsRmVycmV0NFU=
    debugger;
    console.log(appointmentURI);

    axios
      .get(appointmentURI, { timeout: 15000 })
      .then(res => {
        console.log(res);
        this.setState({ appointments: res.data });
        debugger;
        if ((res.data[0].expert_full_name === 'No Appointments') === true) {
          this.setState({
            appointmentsFound: false,
            appointmentsStage: 'none'
          });
        }
        if (res.data[0].appointment_id > -1) {
          this.setState({
            appointmentsFound: true,
            appointmentsStage: 'found'
          });
        } else {
          this.setState({
            appointmentsFound: false,
            appointmentsStage: 'none'
          });
        }
      })
      .catch(function(error) {
        console.log(error);
      });

    // console.log(res);
    // this.setState({ appointments: res.data });
    // debugger;
    // if (res.data[0].appointment_id > 0) {
    //   this.setState({ appointmentsFound: true });
    // }

    //debugger;
  };

  renderAppointments() {
    var hideHeadingDuringChat = this.props.hideHeadingDuringChat;
    // var resetAppointmentsState = this.props.resetAppointmentsState;
    return this.state.appointments.map(appointment => (
      <AppointmentDetail2
        key={appointment.appointment_id}
        appointment={appointment}
        hideHeadingDuringChat={hideHeadingDuringChat.bind(this)}
        resetAppointmentsState={this.resetAppointmentsState.bind(this)}
        appointmentsStage={this.state.appointmentsStage}
        appointmentsFound={this.state.appointmentsStage}
      />
    ));
    return <div>OK</div>;
  }

  render() {
    var hideHeadingDuringChat = this.props.hideHeadingDuringChat;
    return (
      <div className="row justify-content-center">
        <Heading />
        <div className="w-100" />
        <div className="col-md-10 col-lg-10 col-xl-10">
          <div>Appointment List</div>
          <hr />
          {/* <button onClick={() => hideHeadingDuringChat(true)}>
            Test Function
          </button> */}
          {this.state.appointmentsStage === 'searching' && <Searching />}
          {this.state.appointmentsFound === true && this.renderAppointments()}
          {this.state.appointmentsStage === 'none' && <NoAppointments />}
        </div>
      </div>
    );
  }
}

export default AppointmentList;
