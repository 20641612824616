import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import '@opentok/client';
import axios from 'axios';
import {
  OTSession,
  OTPublisher,
  // OTStreams,
  OTSubscriber,
  createSession,
  OTStreams
} from 'opentok-react';
import { getApiURL, getTextURL } from '../Config';
import Iframe from 'react-iframe';
class ChatSessionMainSite extends Component {
  constructor(props) {
    super(props);
    //const hideHeadingDuringChat = props.location.state.hideHeadingDuringChat;

    this.state = {
      sessionId: '',
      name: '',
      uid: 0,
      expertFlg: '',
      apiKey: '',
      token: '',
      archivingActive: false,
      sessionActive: false,
      disconnectRun: false,
      apiInfo: {},
      credentialsPresent: false,
      streams: [],
      exitURL: '',
      textIframeParams: '',
      publisherProperties: {
        name: 'Unknown User',
        height: '150px'
      },

      archiving: {
        status: false,
        buttonText: 'Start Archiving',
        guid: 'NA',
        returnMessage: 'NA',
        apiReturn: {}
      },
      expertUserId: 8,
      debuggingDiv: false,
      exitPage: false
    };

    this.publisherProperties = {
      showControls: true,
      name: 'My Stream',
      height: '150px'
    };
    this.publisherEventHandlers = {
      streamCreated: event => {
        console.log('Publisher stream created!');
        // alert('In streamCreated');
        // this.hideHeadingDuringChat(true);
      },
      streamDestroyed: event => {
        console.log('Publisher stream destroyed!');
        // alert('In streamDestroyed');
        //this.hideHeadingDuringChat(false);
      }
    };

    this.sessionEventHandlers = {
      archiveStarted: event => {
        console.log('event handler archive started');
      },
      archiveStopped: event => {
        console.log('event handler archive stopped');
      }
      // Api calls here for closing session in db
    };

    this.iframeParams = '';
  }
  //   hideHeadingDuringChat = status => {
  //     this.props.location.state.hideHeadingDuringChat(status);
  //   };
  componentWillMount() {
    const { session_id, apiKey, token, userName } = this.props.match.params;
    //debugger;
    this.sessionHelper = createSession({
      apiKey: apiKey,
      sessionId: session_id,
      token: token,
      onStreamsUpdated: streams => {
        this.setState({ streams });
      }
    });

    // var userName = JSON.parse(sessionStorage.getItem(session_id))
    //   .user_screen_name;

    this.setState({ publisherProperties: { name: userName } });
    this.setState({ name: userName });
    this.publisherProperties.name = userName;

    //var userId = JSON.parse(sessionStorage.getItem(session_id)).uid;
    debugger;
    var userId = JSON.parse(sessionStorage.getItem(session_id)).uid;
    var iframeParams = `?apiKey=${apiKey}&sessionId=${session_id}&token=${token}&userId=${userId}&userName=${userName}`;
    this.setState({ textIframeParams: iframeParams });
    this.iframeParams = iframeParams;
    debugger;
  }
  componentDidMount() {
    const { session_id, apiKey, token, userName } = this.props.match.params;
    console.log('In componentDidMount');
    // var userName = JSON.parse(sessionStorage.getItem(session_id))
    //   .user_screen_name;
    //var userName = sessionStorage.getItem('redirUserName');
    const userId = sessionStorage.getItem('userId');
    this.setState({ exitURL: `/redirectapptlist/${userId}/${userName}}` });
    debugger;
    this.setState({ publisherProperties: { name: userName } });
    this.setState({ name: userName });
    this.publisherProperties.name = userName;
    //var userId = JSON.parse(sessionStorage.getItem(session_id)).uid;

    var iframeParams = `?apiKey=${apiKey}&sessionId=${session_id}&token=${token}&userId=${userId}&userName=${userName}`;
    this.setState({ textIframeParams: iframeParams });
    this.iframeParams = iframeParams;
    debugger;
    console.log('In componentDidMount');

    var chatSessionInfo = {};
    chatSessionInfo = JSON.parse(sessionStorage.getItem(session_id));
    console.log('chatSessionInfo');
    console.log(chatSessionInfo);

    this.getApiData(chatSessionInfo);
  }

  componentWillUnmount() {
    // Unbind listener
    debugger;
    if (!this.state.disconnectRun) {
      this.disconnectChat();
    }
    // this.props.history.push('/what');
  }

  getApiData = async chatSessionInfo => {
    const appointmentURI =
      getApiURL() + '/tbsession/' + chatSessionInfo.session_id + '/session';
    axios
      .get(appointmentURI, { timeout: 15000 })
      .then(res => {
        console.log('Return From API Call');
        console.log(res.data);
        const incoming = res.data[0];
        //debugger;
        const apiInfo = {
          // apiKey: incoming.apiKey,
          // session_id: incoming.session_id,
          // token: incoming.token,
          // apiKeyInt: incoming.apiKeyInt
        };
        //debugger;
        this.setState({
          apiKey: incoming.apiKey,
          sessionId: incoming.session_id,
          uid: chatSessionInfo.uid,
          expertFlg: chatSessionInfo.expert_flg,
          token: incoming.token,
          expertUserId: chatSessionInfo.expert_user_id
        });
        //debugger;
        this.setState({ apiInfo: apiInfo, credentialsPresent: true });

        //debugger;
      })
      .catch(function(error) {
        console.log(error);
        return 'Error Getting Session Info';
      });
    console.log('Finished running getApiData()');

    return 'Obtained Session Info';
  };

  archivingToggle = () => {
    // call kaigie api first

    //var current_url = window.location.href.toString();
    const { session_id } = this.props.match.params;
    var kaigieSessionAPI = getApiURL() + '/archiveprocess/';
    //debugger;
    var archiving_action = this.state.archiving.status ? 'stop' : 'start';

    var archiving_info = session_id + ',' + archiving_action;
    var incomingArchiveAction = archiving_action;
    //debugger;
    this.setState({ archivingActive: true });
    if (archiving_action === 'stop') {
      //var guid_div = $('archive_guid_div');
      this.setState({ archivingActive: false });
      var archive_guid = this.state.archiving.guid;

      archiving_info += ',' + archive_guid;
    }

    const archiving_param = btoa(archiving_info);
    kaigieSessionAPI += archiving_param;
    //debugger;
    axios
      .get(kaigieSessionAPI)
      .then(response => {
        var res = response.data[0];
        //debugger;
        var newStatus = !this.state.archiving.status;
        // this.setState({
        //   archiving: { status: newStatus, buttonText: newButtonText }
        // });
        this.setState({
          archiving: {
            guid: res.archive_guid,
            apiReturn: res,
            returnMessage: res.archive_status,
            status: newStatus,
            buttonText: newButtonText
          }
        });
        //check this.state.archiving
      })
      .catch(error => {
        //debugger;
        console.log('error in api call');
      });

    var newButtonText = '';
    //might be better to use incomingArchiveAction instead
    if (this.state.archiving.status === true) {
      //console.log('Archiving Started');
      newButtonText = 'Start Archiving';
      // debugger;
      // this.sendArchiveEmail(incomingArchiveAction);
    } else {
      //  console.log('Archiving Stopped');
      newButtonText = 'Stop Archiving';
      // debugger;
      // this.sendArchiveEmail(incomingArchiveAction);
    }
    //debugger;
    this.sendArchiveEmail(incomingArchiveAction);
  };

  sendArchiveEmail = incomingAction => {
    //start if here

    if (incomingAction === 'stop') {
      //debugger;
      const { session_id } = this.props.match.params;
      const baseDomain = window.location.hostname;
      if (baseDomain === 'localhost') {
        baseDomain = 'prod.kaigie.com';
      }

      var currentArchive = {
        session_id: session_id,
        archive_guid: this.state.archiving.guid,
        user_screen_name: 'Unknown User Name',
        text_content: 'NA', //text content updated later in sql server, can also be done in text app
        base_domain: baseDomain,
        expert_user_id: this.state.expertUserId,
        mode: 'remote'
      };
      //debugger;
      axios
        .post(getApiURL() + '/archiveemail', currentArchive)
        .then(response => {
          //debugger;
          console.log(response);
        })
        .catch(error => {
          //debugger;
          console.log('error in api call');
        });
    }
  };

  disconnectChat = async () => {
    debugger;
    const { session_id } = this.props.match.params;

    var end_call = '/patientcancel';
    if (this.state.expertFlg === 'Y') {
      end_call = '/expertend';
    }
    var base_api = getApiURL() + '/videochatupdate/';

    const updateURL = base_api + session_id + end_call;

    var res = await axios.get(updateURL);

    console.log(res);

    var { archivingActive, expertFlg } = this.state;
    debugger;
    if (archivingActive && expertFlg) {
      this.archivingToggle('stop');
      this.sendArchiveEmail();
    }
    // all lines saved - add code to api to check if messages exist, maybe define send mail 2019 for this
    // else if (text_active == true) {
    //     this.finalTextUpdate();
    // }
    debugger;

    this.setState({ exitPage: true });
  };

  render() {
    const { session_id, apiKey, token } = this.props.match.params;
    // if (this.state.exitPage === true) {

    //   return <Redirect to={this.state.exitURL} />}
    //   }

    //this.hideHeadingDuringChat.bind(this);
    //const { iframeParams } = this.props;
    var textURL = getTextURL();
    var iframeUrl = textURL + this.state.textIframeParams;
    console.log('iFrameURL: ' + iframeUrl);
    //debugger;
    const exitUrl = this.state.exitURL;

    console.log('In componentDidMount');
    // var userName = JSON.parse(sessionStorage.getItem(session_id))
    //   .user_screen_name;
    //var userName = sessionStorage.getItem('redirUserName');
    const userId = sessionStorage.getItem('userId');
    const userName = sessionStorage.getItem('redirUserName');
    return (
      <div className="chat-element">
        {this.state.exitPage && (
          <Redirect
            to={{
              pathname: `/redirectapptlist/${userId}/${userName}`
            }}
          />
        )}
        <div>Kaigie Video Chat Session From Main Site</div>
        <div id="componentRow" className="row justify-content-center">
          <div>
            {this.state.expertFlg === 'Y' && (
              <button
                id="archivingButton"
                className={
                  'btn btn-sm ' +
                  (this.state.archiving.status
                    ? 'btn-danger '
                    : 'btn-success') +
                  ' m-1 p-1 kaigie-btn'
                }
                onClick={this.archivingToggle}
              >
                {this.state.archiving.buttonText}
              </button>
            )}

            <button
              id="exitButton"
              className="btn kaigie-btn btn-primary m-1 p-1"
              onClick={this.disconnectChat}
            >
              Exit
            </button>
          </div>

          <div className="w-100 clearfix" />

          {/* <button onClick={() => this.hideHeadingDuringChat(true)}>
            Test Function
          </button> */}
          <div id="videoContainerDiv">
            <div id="chatDiv">
              <div id="videoComponents">
                {this.state.credentialsPresent && (
                  <OTSession
                    apiKey={apiKey}
                    sessionId={session_id}
                    token={token}
                    eventHandlers={this.sessionEventHandlers}
                    id={token}
                  >
                    <div>
                      <div>
                        <div className="row video-row">
                          <div
                            id="publisherCol"
                            className="p-3 m-3 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 video-col panel-background-5"
                          >
                            <div className="card video-card ">
                              <div className="card-body">
                                <OTPublisher
                                  properties={this.state.publisherProperties}
                                  eventHandlers={this.publisherEventHandlers}
                                />

                                <div>{/* insert here */}</div>
                              </div>
                            </div>
                          </div>

                          <div
                            id="subscriberCol"
                            className="p-3 m-3 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 video-col panel-background-5"
                          >
                            <div className="card video-card">
                              <div className="card-body">
                                {this.state.streams.map(stream => {
                                  return (
                                    <OTStreams>
                                      <OTSubscriber
                                        key={sessionStorage.getItem(
                                          'currentUser'
                                        )}
                                      />
                                    </OTStreams>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </OTSession>
                )}

                <div className="clearfix p-2" />
              </div>
            </div>
          </div>
        </div>

        <div id="textContainerDiv" className="d-none d-sm-block">
          {/* {window.innerWidth > 767 && ( */}
          <Iframe
            url={iframeUrl}
            width="550px"
            height="320px"
            id="myId"
            display="initial"
            position="relative"
          />
          {/* )} */}
        </div>

        {this.state.expertFlg === 'Y' && this.state.debuggingDiv && (
          <div id="sessionInfoDiv" className="p-3">
            <div>Debugging Info</div>
            <div className="clearfix" />
            <div>Archive GUID: {this.state.archiving.guid}</div>
            <div className="clearfix" />
            <div>Archiving Result: {this.state.archiving.returnMessage}</div>
          </div>
        )}
      </div>
    );
  }
}
// var videoContainerStyle = {
//   width: '90%',
//   marginLeft: 'auto',
//   marginRight: 'auto'
// };

export default ChatSessionMainSite;
