import React, { Component } from 'react';
//import ReactDom from 'react-dom';
import Heading from './common/Heading';
import { Button, FormGroup, FormControl } from 'react-bootstrap';
import axios from 'axios';
import { getApiURL } from '../Config';

//import { Redirect } from 'react-router';

export default class Logon extends Component {
  constructor(props) {
    super(props);
    //const apiUrl = getApiURL();
    this.state = { username: '', password: '', loggedOnOK: false };
  }
  redirect() {
    this.props.history.push('/appointments');
  }
  //loggedOnOK = false;
  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    console.log(this.state);

    var user_info = this.state.username + ',' + this.state.password;
    const userInfoA = user_info;
    sessionStorage.setItem('userInfoA', btoa(userInfoA));
    window.sessionStorage.setItem('user_screen_name', this.state.username);
    //   $("#hdUserName").val(user_name.value);
    user_info = btoa(user_info);
    sessionStorage.setItem('logonInfo', user_info);
    //kaigieAPI += user_info;

    var logonInfo = { logon: user_info, action: 'logon' };
    var apiURL = getApiURL();
    var logonAPI = apiURL + '/fulluser/validate';

    const res = await axios.post(logonAPI, logonInfo).catch(function(error) {
      console.log(error);
    });
    debugger;
    const logonStatus = res.data[0];

    console.log('Logon Status');
    console.log(logonStatus);
    console.log('logon_status = ' + logonStatus.logon_status);

    if (logonStatus.logon_status === 'FAILURE') {
      alert('Logon Failed');
    } else {
      //alert('Logon Successful, get appointments');
      //this.setState({ loggedOnOK: true });
      console.log(logonStatus);
      //this.setState({ loggedOn: true });
      debugger;
      var userFilter = userInfoA + ',' + logonStatus.expert_flg;
      userFilter = btoa(userFilter);
      var loggedOn =
        userInfoA + ',' + logonStatus.expert_flg + ',' + logonStatus.user_id;

      console.log(loggedOn);

      loggedOn = btoa(loggedOn);
      console.log('Encrypted: ' + loggedOn);
      sessionStorage.setItem('loggedOn', loggedOn);
      sessionStorage.setItem('userFilter', userFilter);
      sessionStorage.setItem('currentUser', logonStatus.user_id);

      //debugger;
      this.props.history.push('/appointments');
    }
  };

  render() {
    return (
      <div className="row justify-content-center">
        <Heading />
        <div className="w-100" />
        <div className="col-md-8 col-lg-6 col-xl-4 card p-2 m-2">
          <h5 className="card-title m-3 p-3 card-title-color">Login Here!</h5>

          <form onSubmit={this.handleSubmit} className="card-body logon-card">
            <div className="form-horizontal">
              <FormGroup controlId="username" className="p1 m-1">
                <label className="p-3">User Name</label>
                <FormControl
                  type="text"
                  placeholder="user name"
                  value={this.state.username}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup controlId="password">
                <label className="p-3">Password</label>
                <FormControl
                  type="password"
                  placeholder="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <div className="card-footer">
                <Button type="submit" className="btn-sm">
                  Submit{' '}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
