import React from 'react';

export default function FlowTest() {
  return (
    <div>
      <div className="container">
        <div className="row  justify-content-center">
          <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 col-xl-3">
            one
          </div>
          <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 col-xl-3">
            two
          </div>
          <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 col-xl-3">
            three
          </div>
          <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 col-xl-3">
            four
          </div>
        </div>
        <div>reverse</div>
        <div className="row" style={flexReverse}>
          <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 col-xl-3">
            one
          </div>
          <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 col-xl-3">
            two
          </div>
          <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 col-xl-3">
            three
          </div>
          <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 col-xl-3">
            four
          </div>
          <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 col-xl-3">
            one
          </div>
          <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 col-xl-3">
            two
          </div>
          <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 col-xl-3">
            three
          </div>
          <div className="col-xs-12 col-sm-8 col-md-4 col-lg-4 col-xl-3">
            four
          </div>
        </div>
      </div>
    </div>
  );
}

const flexNormal = {
  display: 'flex',
  flexFlow: 'rowReverse wrap'
};
const flexReverse = {
  display: 'flex',
  flexFlow: 'colReverse wrap'
};
