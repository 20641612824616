import React from 'react';
import Loader from 'react-loader-spinner';

export default function Searching() {
  return (
    <div>
      <div className="card">
        <div className="card-title">
          Searching for Appointments, Please Wait...
        </div>

        <div className="card-body">
          <Loader type="Puff" color="#00BFFF" height="100" width="100" />
        </div>
      </div>
    </div>
  );
}
