import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'react-bootstrap/dist/react-bootstrap.min.js';
import { apiURL, getApiURL } from '../Config';

class AppointmentDetail2 extends Component {
  constructor(props) {
    super(props);

    var showDebugging = false;

    this.state = {
      authInfo: {
        apiKey: '',
        sessionId: '',
        token: ''
      }
    };
  }
  createMarkup = description => {
    return { __html: description };
  };

  componentWillMount() {
    const appointment = this.props.appointment;
    debugger;
    //  0    var otherUserName =
    //       sessionStorage.getItem('currentUser') === appointment.expert_user_id
    //         ? appointment.user_full_name
    //         : appointment.expert_full_name;
    var uid;
    if ((appointment.expert_flg === 'Y') === true) {
      uid = appointment.expert_user_id;
    } else {
      uid = appointment.user_id;
    }

    var savedChatParams = {
      session_id: appointment.session_id,
      id: appointment.appointment_id,
      uid: uid,
      expert_flg: appointment.expert_flg,
      expert_user_id: appointment.expert_user_id
    };

    console.log('Saved Chat Params ' + savedChatParams);
    var savedChatParamsJSON = JSON.stringify(savedChatParams);
    sessionStorage.setItem(appointment.session_id, savedChatParamsJSON);
    debugger;
    this.chatURI = './ChatSession/' + appointment.session_id;
    this.getApiData(appointment.session_id);
  }

  componentDidMount() {}

  getApiData = async sessionId => {
    var authInfo = { sessionId: sessionId, apiKey: '', token: '' };

    var apiURL = getApiURL();
    const appointmentURI = apiURL + '/tbsession/' + sessionId + '/session';
    await axios
      .get(appointmentURI, { timeout: 15000 })
      .then(res => {
        // console.log('Return From API Call');
        // console.log(res.data);
        const incoming = res.data[0];

        authInfo.apiKey = incoming.apiKey;
        authInfo.token = incoming.token;

        this.setState({ authInfo: authInfo });
      })
      .catch(function(error) {
        console.log(error);
        return 'Error Getting Session Info';
      });
  };

  render() {
    var hideHeadingDuringChat = this.props.hideHeadingDuringChat;
    var resetAppointmentsState = this.props.resetAppointmentsState;
    const { appointment, showDebugging } = this.props;

    var linkURL = `/chatsession2/${appointment.session_id}/${
      this.state.authInfo.apiKey
    }/${this.state.authInfo.token}`;
    var currentUser = sessionStorage.getItem('currentUser');
    var otherUser =
      currentUser === String(appointment.expert_user_id)
        ? appointment.user_full_name
        : appointment.expert_full_name;
    console.log('otherUser is ' + otherUser);
    return (
      <div className="row justify-content-center mb-2">
        <div className="card panel-background-9" style={cardStyle}>
          <div className="card-body">
            <div className="card-title text-black">
              <div>{otherUser}</div>
              {/* <div>
                {' '}
                <button onClick={() => hideHeadingDuringChat(true)}>
                  Test Function
                </button>
              </div> */}
              <div>
                {` ${appointment.scheduled_date_time_utc.replace('T', ' ')}`}
              </div>
            </div>
            <div className="card-text">
              <div> Chat Purpose</div>
              <div
                dangerouslySetInnerHTML={this.createMarkup(
                  appointment.chat_purpose
                )}
              />

              {showDebugging && (
                <div className="d-none d-md-block">
                  <div>Debug Info</div>
                  <div>Session ID {appointment.session_id}</div>
                </div>
              )}
            </div>

            <div className="card-footer">
              <div className="mt-1">
                {/* <Link
                  className="btn btn-success btn-sm kaigie-btn"
                  to={`/chatsession2/${appointment.session_id}/${
                    this.state.authInfo.apiKey
                  }/${this.state.authInfo.token}`}
                  hideHeadingDuringChat={hideHeadingDuringChat.bind(this)}
                >
                  Go To Chat
                </Link> */}
                <div className="w-100" />
                <Link
                  className="btn btn-success btn-sm kaigie-btn"
                  to={{
                    pathname: linkURL,
                    state: {
                      hideHeadingDuringChat: hideHeadingDuringChat.bind(this),
                      resetAppointmentsState: resetAppointmentsState.bind(this),
                      statePassed: true
                      // appointmentsStage: this.appointmentsStage.bind(this),
                      // appointmentsFound: this.appointmentsFound.bind(this)
                    }
                  }}
                >
                  Go To Chat
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// var btnStyle = {
//   marginTop: '10px'
// };

var cardStyle = {
  width: '94%',
  maxWidth: '600px'
};

export default AppointmentDetail2;
