import React from 'react';
import ReactDOM from 'react-dom';
import {} from 'react-router-dom';
//import HttpsRedirect from 'react-https-redirect';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import '@opentok/client';
import 'bootstrap/dist/css/bootstrap.css';

ReactDOM.render(
  // <HttpsRedirect>
  //   <App />
  // </HttpsRedirect>
  <App />,
  document.getElementById('root')
);
// add "homepage" with url and subdirectory
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
