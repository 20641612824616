import React, { Component } from 'react';

export default class Heading extends Component {
  render() {
    return (
      <div>
        <div className="page-heading card-title-color mb-2 pb-2">
          Kaigie Video Chat
        </div>
      </div>
    );
  }
}
